import { createAuthProvider } from 'react-token-auth';

const { REACT_APP_API_URL } = process.env;

const ls = {
  get: (key) => JSON.parse(global.localStorage.getItem(key)),
  set: (key, value) => {
    try {
      global.localStorage.setItem(key, JSON.stringify(value));
      return true;
    } catch (e) {
      return false;
    }
  },
  pop: (key) => {
    if (global.localStorage.getItem(key)) {
      const value = JSON.parse(global.localStorage.getItem(key));
      global.localStorage.removeItem(key);
      return value;
    }
    return null;
  },
  remove: (key) => global.localStorage.removeItem(key),
  clear: () => global.localStorage.clear(),
};

const [useAuth, authFetch, login, logout] = createAuthProvider({
  accessTokenKey: 'access_token',
  onUpdateToken: (token) => fetch(`${REACT_APP_API_URL}/auth/refresh`, {
    method: 'POST',
    headers: { Authorization: `Bearer ${token.access_token}` },
  })
    .then((response) => response.json())
    .then((response) => {
      if (response.status_code === 200) {
        return response.data;
      }
      return response;
    }),
});

const sendRequest = (route, method, body = {}, headers = {}) => {
  let url = `${REACT_APP_API_URL}/${route}`;

  const params = {
    method,
    headers: {
      'Content-Type': 'application/json',
      ...headers,
    },
  };

  if (method === 'GET') {
    url += `?${new URLSearchParams(body)}`;
  } else {
    params.body = JSON.stringify(body);
  }

  // Avoid caching results from API
  params.cache = 'reload';

  // TODO: Refactor! Add action in case of errors
  return authFetch(url, params)
    .then((response) => response.text())
    .then((text) => {
      try {
        return JSON.parse(text);
      } catch (error) {
        return {
          status_code: 500,
          message: 'Something went wrong on our side. Please contact us: support@ventor.tech',
        };
      }
    });
  // .catch((err) => alert(err));
};

const getCurrentUser = async (force = true) => {
  let currentUser = null;
  try {
    currentUser = ls.get('user');
  } catch (e) {
    // Do nothing
  }

  if (!currentUser || force) {
    return sendRequest('accounts', 'GET')
      .then((response) => {
        if (response.status_code !== 200) {
          logout();
        }

        ls.set('user', response.data);
        return response.data;
      })
      .catch(() => logout());
  }

  return currentUser;
};

const sendPrintnodeRequest = async (path, method = 'GET') => {
  const currentUser = await getCurrentUser();

  if (currentUser) {
    if (!currentUser.subscription || !currentUser.subscription.printnode_account) {
      // TODO: Refactor?
      throw Error('You have no active subscription');
    }

    const { key } = currentUser.subscription.printnode_account.api_keys[0];

    return fetch(
      `https://api.printnode.com/${path}`,
      {
        method,
        headers: {
          Authorization: `Basic ${btoa(`api:${key}`)}`,
        },
      },
    );
  }
  // Possibly not authorized user? Do logout
  logout();
  return null;
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_INIT':
      return {
        ...state,
        isLoading: true,
        isError: false,
        message: '',
        error: '',
      };
    case 'FETCH_SUCCESS':
      return {
        ...state,
        isLoading: false,
        isError: false,
        data: (typeof action.payload !== 'undefined') ? action.payload : state.data,
        message: action.message,
        error: '',
      };
    case 'FETCH_FAILURE':
      return {
        ...state,
        isLoading: false,
        isError: true,
        message: '',
        error: action.error,
      };
    default:
      throw new Error();
  }
};

const round = (number, digits = 0) => (
  Math.round(number * (10 ** digits)) / (10 ** digits)
);

const numberWithSpaces = (x) => x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

export {
  ls, getCurrentUser, useAuth, authFetch, login, logout, reducer, round,
  sendPrintnodeRequest, sendRequest, numberWithSpaces,
};
