import React from 'react';
import { Alert, Button, Form } from 'react-bootstrap';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import Loader from '../Components/Loader';
import { sendRequest } from '../utils';

function calculateTotal(extraPrints, pricePerPrint) {
  return (extraPrints * pricePerPrint).toFixed(2);
}

function calculateExtraPrints(subscription) {
  // Extra prints calculated as difference between total prints, prints included in plan
  // And sum of paid_extra_prints_in_current_period records
  const paidExtraPrints = subscription.paid_extra_prints_in_current_period.reduce(
    (acc, record) => acc + record.number_of_prints,
    0,
  );

  const extraPrints = (
    subscription.total_prints
    - subscription.plan.prints_included
    - paidExtraPrints
  );

  return extraPrints > 0 ? extraPrints : 0;
}

const PayForExtraPrints = () => {
  const location = useLocation();

  const [extraPrints, setExtraPrints] = React.useState(1);
  const [minExtraPrints, setMinExtraPrints] = React.useState(1);
  const [pricePerPrint, setPricePerPrint] = React.useState(0);
  const [deadline, setDeadline] = React.useState('');

  const [isLoading, setIsLoading] = React.useState(false);
  const [error, setError] = React.useState('');
  const [success, setSuccess] = React.useState('');

  React.useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const successParam = queryParams.get('success');
    if (successParam === 'true') {
      setSuccess('Payment was successful. Thank you!');
    } else if (successParam === 'false') {
      setError('Payment was not successful. Please, try again.');
    }
  }, [location]);

  React.useEffect(() => {
    setIsLoading(true);

    sendRequest('subscriptions', 'GET', { active_only: true })
      .then((response) => {
        if (response.status_code === 200) {
          const subscriptions = response.data;
          // Assume that first of subscription will be required
          // TODO: Of course this will not work with multiple subscription for different products
          const subscription = subscriptions[0];

          if (subscription.total_prints > subscription.plan.prints_included) {
            setExtraPrints(calculateExtraPrints(subscription));
            setMinExtraPrints(calculateExtraPrints(subscription));

            // Save deadline date in user friendly format
            setDeadline(moment.utc(
              subscription.date_current_period_end,
            ).local().format('MMMM D YYYY, H:mm:ss'));
          } else {
            setExtraPrints(0);
            setMinExtraPrints(0);
          }
          setPricePerPrint(subscription.plan.price_per_print);
        } else if (response.status_code === 404) {
          setError('You have no active subscription');
        } else {
          setError(response.message);
        }
        setIsLoading(false);
      })
      .catch((e) => {
        setError(e.message || 'Something went wrong');
      });
  }, []);

  const onExtraPrintsChange = (e) => {
    const newExtraPrints = parseInt(e.target.value, 10);

    if (newExtraPrints < minExtraPrints) {
      setError(`Minimum number of extra prints is ${minExtraPrints}`);
    } else {
      setError('');
    }

    setExtraPrints(newExtraPrints);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsLoading(true);
    setError('');
    setSuccess('');

    const opts = {
      date: new Date().toLocaleDateString(),
      extra_prints: extraPrints,
      price_per_print: pricePerPrint,
    };

    sendRequest('checkout-sessions', 'POST', opts)
      .then((data) => {
        if (data.status_code !== 200) {
          setError(data.message || 'An error occurred during payment');
          setIsLoading(false);
          return;
        }

        const sessionUrl = data.data.session_url;
        if (sessionUrl) {
          window.location.href = sessionUrl;
        } else {
          setError(data.message || 'An error occurred during payment');
        }
      })
      .catch((err) => {
        setError(err.message || 'An error occurred during payment');
      });
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className="card mt-4">
      <div className="card-body">
        <h2 className="mb-4">Pay for Extra Prints</h2>

        {error && <Alert variant="danger">{error}</Alert>}
        {success && <Alert variant="success">{success}</Alert>}

        <Alert variant="warning" className="mb-4">
          You&apos;ve printed
          {' '}
          {minExtraPrints}
          {' '}
          extra documents (excluding already paid extra prints). Use this secure payment form
          to settle your balance by
          {' '}
          <span className="text-nowrap font-weight-bold">
            {deadline}
          </span>
          {' '}
          to keep your account active. Payments not made
          by this date may result in account suspension.
        </Alert>

        <Form onSubmit={handleSubmit} className="row">
          <Form.Group controlId="numPrints" className="col-md-6">
            <Form.Label>Number of Prints</Form.Label>
            <Form.Control
              type="number"
              value={extraPrints}
              onChange={onExtraPrintsChange}
              min="1"
              required
            />
            <small id="numPrints" className="form-text text-muted">
              You have
              {' '}
              {minExtraPrints}
              {' '}
              extra prints. You can specify more if needed.
            </small>

          </Form.Group>

          <Form.Group controlId="numPrints" className="col-md-6">
            <Form.Label>Price per Print, €</Form.Label>
            <Form.Control
              type="string"
              value={`${pricePerPrint} €`}
              min="1"
              readOnly
            />
          </Form.Group>

          <Form.Group controlId="totalCost" className="col-md-12">
            <Form.Label>Total Cost</Form.Label>
            <Form.Control
              type="text"
              value={`${calculateTotal(extraPrints, pricePerPrint)} €`}
              readOnly
            />
          </Form.Group>

          <div className="col-md-12">
            <Button type="submit" disabled={isLoading || extraPrints === 0 || extraPrints < minExtraPrints} variant="primary">
              {isLoading ? 'Processing...' : 'Pay Now'}
            </Button>
            <small className="ml-3">
              <i>
                You will be redirected to the secure payment form to complete the transaction.
              </i>
            </small>
          </div>

        </Form>

      </div>
    </div>
  );
};

export default PayForExtraPrints;
