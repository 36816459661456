import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';
import { Alert, Badge, Button, Card, Pagination } from 'react-bootstrap';
import moment from 'moment';
import { reducer, sendRequest } from '../utils';

const DEFAULT_PAGE_SIZE = 10;

const Invoice = ({ data }) => {
  const link = `${process.env.REACT_APP_API_URL}/download/invoice?stripeId=${data.stripe_id}`;

  const onDownload = (e) => {
    e.preventDefault();
    window.open(link, '_blank');
  };

  return (
    <tr>
      <td>
        €
        {data.amount_paid}
      </td>
      <td>
        {
          data.status === 'paid'
            ? <Badge variant="success">{data.status}</Badge>
            : <Badge variant="warning">{data.status}</Badge>
        }
      </td>
      <td className="text-nowrap">{data.number}</td>
      <td className="text-nowrap">
        {
          moment.utc(data.date_created).local().format('MMMM D YYYY, H:mm:ss')
        }
      </td>
      <td>
        <Button
          onClick={onDownload}
          rel="noreferrer"
          variant="outline-primary"
        >
          <FontAwesomeIcon aria-label="Download" icon="download" />
        </Button>
      </td>
    </tr>
  );
};

Invoice.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number.isRequired,
    amount_paid: PropTypes.number.isRequired,
    status: PropTypes.string.isRequired,
    number: PropTypes.string,
    date_created: PropTypes.string.isRequired,
    stripe_id: PropTypes.string,
  }).isRequired,
};

const Invoices = () => {
  const [pagesNum, setPagesNum] = React.useState(0);
  const [currentPage, setCurrentPage] = React.useState(1);

  const [state, dispatch] = React.useReducer(
    reducer,
    { data: [], error: '', message: '', isLoading: false, isError: false },
  );

  const changePage = (event) => {
    const pageNumber = Number(event.target.textContent);
    if (pageNumber <= pagesNum) {
      setCurrentPage(pageNumber);
    }
  };

  const createPaginationItem = (i) => (
    <Pagination.Item key={i} active={i === currentPage} onClick={changePage}>
      {i}
    </Pagination.Item>
  );

  const goToNextPage = () => {
    setCurrentPage((page) => page + 1);
  };

  const goToPreviousPage = () => {
    setCurrentPage((page) => page - 1);
  };

  function isCompositeTypeElement(element) {
    return React.isValidElement(element) && typeof element.type === 'function';
  }

  const generatePageRange = (current, last, delta = 2) => {
    // creates array with base 1 index
    const range = Array(last)
      .fill()
      .map((_, index) => index + 1);

    return range.reduce((pages, page) => {
      // allow adding of first and last pages
      if (page === 1 || page === last) {
        return [...pages, createPaginationItem(page)];
      }

      // if within delta range add page
      if (page - delta <= current && page + delta >= current) {
        return [...pages, createPaginationItem(page)];
      }

      // otherwise add 'gap if gap was not the last item added.
      if (!isCompositeTypeElement(pages[pages.length - 1])) {
        return [...pages, <Pagination.Ellipsis />];
      }

      return pages;
    }, []);
  };

  const paginationItems = generatePageRange(currentPage, pagesNum);

  const getPaginatedData = () => {
    const startIndex = currentPage * DEFAULT_PAGE_SIZE - DEFAULT_PAGE_SIZE;
    const endIndex = startIndex + DEFAULT_PAGE_SIZE;
    return state.data.slice(startIndex, endIndex);
  };

  React.useEffect(() => {
    dispatch({ type: 'FETCH_INIT' });

    sendRequest('invoices', 'GET')
      .then((response) => {
        if (response.status_code === 200) {
          dispatch({ type: 'FETCH_SUCCESS', payload: response.data });
          setPagesNum(Math.ceil(response.data.length / DEFAULT_PAGE_SIZE));
        } else {
          dispatch({ type: 'FETCH_FAILURE', error: response.error });
        }
      })
      .catch((error) => {
        dispatch({ type: 'FETCH_FAILURE', error });
      });
  }, []);

  return (
    <Card className="mb-4 flex-fill">
      <Card.Body>
        <h5>Invoices</h5>
        {
          state.data.length
            ? (
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Amount Paid</th>
                      <th>Status</th>
                      <th>Invoice Number</th>
                      <th>Created</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {getPaginatedData().map((d) => (
                      <Invoice key={d.id} data={d} />
                    ))}
                  </tbody>
                </table>

                <Pagination className="d-flex justify-content-center align-items-center">
                  <Pagination.First
                    onClick={() => setCurrentPage(1)}
                    disabled={currentPage === 1}
                  />
                  <Pagination.Prev
                    onClick={goToPreviousPage}
                    disabled={currentPage === 1}
                  />
                  {paginationItems}
                  <Pagination.Next
                    onClick={goToNextPage}
                    disabled={currentPage === pagesNum}
                  />
                  <Pagination.Last
                    onClick={() => setCurrentPage(pagesNum)}
                    disabled={currentPage === pagesNum}
                  />
                </Pagination>
              </div>
            )
            : (
              <Alert variant="warning">
                You have no invoices
              </Alert>
            )
        }

      </Card.Body>
    </Card>
  );
};

export default Invoices;
